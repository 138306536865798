<template>
  <div>
    <el-main class="com-eTrip-section section-has-footer">
      <SectionContent style="background-color: #fff">
        <com-list-search>
          <el-form :inline="true" size="medium" slot="list-search-before">
            <el-form-item style="width: 102px">
              <el-date-picker :clearable="false" style="width: 100px" placeholder="请选择年份"
                              v-model="form.signatureYear" format="yyyy"
                              value-format="yyyy" type="year"
                              :picker-options="pickerOptions"
                              @change="handleChange"></el-date-picker>
            </el-form-item>
            <el-form-item style="width: 150px">
              <el-input placeholder="请输入司机姓名" clearable v-model="form.driverName"/>
            </el-form-item>
            <el-form-item style="width: 150px">
              <el-input placeholder="请输入司机手机" clearable
                        v-model="form.driverMobile"/>
            </el-form-item>
            <!--                        <el-form-item>-->
            <!--                            <supplier-select v-model="form.driverSupplierId"-->
            <!--                                             placeholder="请选择供应商"></supplier-select>-->
            <!--                        </el-form-item>-->
            <el-form-item>
              <ApiSelect placeholder="签署状态" v-model="form.isSafeSignature" :options="stateOptions"
                         clearable>
                <el-option :value="-1" label="全部">全部</el-option>
              </ApiSelect>
            </el-form-item>
            <el-form-item>
              <el-select style="width: 270px" v-model="form.isHealth"
                         placeholder="本人或家族其他成员是否有对应病史">
                <el-option :value="-1" label="全部">全部</el-option>
                <el-option value="1" label="有病史">有病史</el-option>
                <el-option value="0" label="无病史">无病史</el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div slot="list-search-after">
            <el-button @click="resetClick()">重置</el-button>
            <el-button type="primary" @click="getList()">搜索</el-button>
            <el-button @click="showSelection=true" v-if="!showSelection">批量打印</el-button>
            <el-button type="warning" v-if="showSelection" @click="handleBatchPrint">确定打印
            </el-button>
            <el-button plain type="warning" v-if="showSelection" @click="showSelection=false">
              取消打印
            </el-button>
            <el-button @click="handlePrintAll()" v-if="!showSelection">打印全部</el-button>
            <el-button @click="handlePreview">健康承诺书预览</el-button>
          </div>
        </com-list-search>
        <el-table style="width: 100%;" border v-loading="loading" :data="result.data"
                  @selection-change="handleSelectionChange">
          <el-table-column v-if="showSelection" type="selection" width="55"></el-table-column>
          <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
          <el-table-column prop="driverName" label="司机姓名" align="center"></el-table-column>
          <el-table-column prop="driverMobile" label="司机手机" align="center"></el-table-column>
          <el-table-column prop="idCardNumber" label="身份证号" align="center"></el-table-column>
          <el-table-column label="本人或家族其他成员是否有对应病史" align="center">
            <template slot-scope="scope">
              {{ scope.row.isHealth === 0 ? '无' : (scope.row.isHealth === 1 ? '有' : '') }}
              <span v-if="scope.row.isHealth===1&&scope.row.optionInfo">
                                {{
                  scope.row.optionInfo.split('、').reduce((a, b, index) => b == 1 ? [...a, index + 1] : a, []).join('、')
                }}
                            </span>
            </template>
          </el-table-column>
          <el-table-column label="服务城市" prop="cityName" align="center"></el-table-column>
          <el-table-column prop="supplierName" label="所属供应商" align="center"></el-table-column>
          <el-table-column prop="driverStatus" label="司机状态" align="center">
            <template slot-scope="scope">
              {{ scope.row.driverStatus === 1 ? '可用' : '停用' }}
            </template>
          </el-table-column>
          <el-table-column label="签署时间" align="center">
            <template slot-scope="scope">{{ scope.row.safeSignatureTime | dateCus }}</template>
          </el-table-column>
          <el-table-column label="签名" align="center">
            <template slot-scope="scope">
              <a href="javascript:void(0)" @click="handleDetail(scope.row)">
                <el-image v-if="scope.row.signaturePhotoUrl"
                          :src="scope.row.signaturePhotoUrl"
                          style="width: 80px;"></el-image>
              </a>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100" align="center">
            <template slot-scope="scope">
              <delete-popover v-if="scope.row.signaturePhotoUrl" :info="scope.row"
                              @onRefresh="getList"></delete-popover>
            </template>
          </el-table-column>
        </el-table>
      </SectionContent>
    </el-main>
    <el-footer style="display: flex;justify-content: flex-end;align-items: center">
      <el-pagination background layout="prev, pager, next"
                     :current-page="form.pageIndex"
                     :total="result.total"
                     @current-change="getList">
      </el-pagination>
    </el-footer>
    <el-dialog :visible.sync="dialogVisible" width="500px">
      <img v-if="currentRecord" style="width: 100%" :src="currentRecord.signaturePhotoUrl" alt="">
      <div slot="footer" style="display: flex;justify-content: flex-end">
        <el-button type="primary" @click="dialogVisible=false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {trainingTypeOptions} from '@/data/driverTraining'
import comListSearch from "@/www/components/comListSearch/index.vue";
import SectionContent from "@/components/sectionContent/index.vue";
import {guangZhouHealthSafeSignatureListApi} from "@/www/urls/driverLearning";
// import SupplierSelect from "@/www/components/supplier-select/index.vue";
import moment from "moment";
import {combinationForm} from "@/common/js/common";
import DeletePopover from "./delete-popover.vue";

const createForm = (form = {}) => ({
  pageIndex: 1,
  pageSize: 10,
  driverName: undefined,
  driverMobile: undefined,
  cityId: undefined,
  driverSupplierId: undefined,
  status: undefined,
  signatureYear: moment().format('YYYY'),
  isSafeSignature: undefined,
  isHealth: undefined,
  ...form
})

export default {
  name: "healthCommitmentLetter",
  data() {
    return {
      showSelection: false,
      stateOptions: [
        {
          value: 0,
          label: '未签署'
        },
        {
          value: 1,
          label: '已签署'
        }
      ],
      trainingTypeOptions,
      loading: false,
      form: createForm(),
      result: {
        data: [],
        total: 0
      },
      dialogVisible: false,
      currentRecord: null,
      pickerOptions: {
        disabledDate(date) {
          return moment(date).isAfter(moment(), 'year')
        }
      },
      popovers: []
    }
  },
  components: {
    comListSearch,
    SectionContent,
    // SupplierSelect,
    DeletePopover
  },
  activated() {
    if (this.$route.query.t !== this._t) {
      this._t = this.$route.query.t;
      this.getList();
    }
  },
  methods: {
    async getList(page = 1) {
      this.form.pageIndex = page;
      this.loading = true;
      const ret = await guangZhouHealthSafeSignatureListApi(combinationForm({
        ...this.form,
        isHealth: this.form.isHealth !== -1 ? this.form.isHealth : undefined,
        isSafeSignature: this.form.isSafeSignature !== -1 ? this.form.isSafeSignature : undefined
      }))
      this.loading = false
      if (ret.success) {
        this.result = ret.data;
      } else this.$message.error(ret.errors[0].message)

    },
    resetClick(form) {
      // this.$refs['select-city'].clear()
      this.form = createForm(form);
      this.getList(1)
    },
    handleDetail(record) {
      this.currentRecord = record;
      this.dialogVisible = true;
    },

    //批量打印
    handleBatchPrint() {
      if (!this.selection || !this.selection.length)
        return this.$alert('请先选择打印项', '提示', {
          type: "error"
        });
      this.showSelection = false;
      this.sessionStorage.setObject('healthCommitmentLetter_signature', this.selection.map(({
                                                                                              signaturePhotoUrl,
                                                                                              safeSignatureTime,
                                                                                              driverName,
                                                                                              idCardNumber,
                                                                                              optionInfo
                                                                                            }) => ({
        signaturePhotoUrl,
        safeSignatureTime,
        driverName,
        idCardNumber,
        optionInfo
      })))
      this.$router.push(`/healthCommitmentLetter/print`)
    },
    handleSelectionChange(selection) {
      this.selection = selection;
    },
    //打印全部数据
    async handlePrintAll(list = [], pageIndex = 1) {
      let total = 0;
      try {
        this.loadingShow()
        const ret = await guangZhouHealthSafeSignatureListApi({
          ...this.form,
          pageIndex
        })
        if (ret.success && ret.data) {
          list.push(...ret.data.data)
          total = ret.data.total
        }
      } finally {
        if (pageIndex * 50 < total) {
          this.handlePrintAll(list, pageIndex + 1);
        } else {
          this.loadingHide();
          if (!list.length) {
            this.$alert('当前搜索条件下无数据', '提示', {
              type: "error"
            });
          } else {
            this.sessionStorage.setObject('healthCommitmentLetter_signature', list.map(({
                                                                                          signaturePhotoUrl,
                                                                                          safeSignatureTime,
                                                                                          driverName,
                                                                                          idCardNumber,
                                                                                          optionInfo
                                                                                        }) => ({
              signaturePhotoUrl,
              safeSignatureTime,
              driverName,
              idCardNumber,
              optionInfo
            })))
            this.$router.push(`/healthCommitmentLetter/print`)
          }
        }
      }
    },
    async handleChange() {
      this.form.pageIndex = 1;
      this.resetClick({
        signatureYear: this.form.signatureYear
      });
    },
    handleSelectCity(city) {
      this.form.cityId = city.shortCode
    },
    handlePreview() {
      this.sessionStorage.setObject('healthCommitmentLetter_signature', [{}])
      this.$router.push(`/healthCommitmentLetter/preview?type=1`)
    }
  }
}
</script>

<style scoped lang="scss">
.eTrip-section-cont {
  margin-top: 10px;

  &::v-deep {
    .list-search-after {
      width: 760px;
    }
  }
}

.message-info-btn {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
